import { query, createAsync, type RouteDefinition } from '@solidjs/router';
import { For } from 'solid-js';

import { gql } from '~/__gql-generated__';
import postStyles from '~/components/Post.module.scss';
import rootStyles from '~/components/Root.module.scss';
import SmartA from '~/components/SmartA';
import Title from '~/components/Title';
import styles from '~/routes/colunas.module.scss';
import { fragmentToJsx } from '~/utils/fragmentToJsx';
import { client } from '~/utils/graphql';
import { conjunctionJoin } from '~/utils/conjunctionJoin';
import { generateWidthThumbs } from '~/utils/generateThumbs';
import renderRawFragment from '~/utils/renderRawFragment';

const CATEGORIES = gql(`
  query Categories {
    categories(where: { deletedAt: null }, options: { sort: { title: ASC } }) {
      title
      slug
      genre
      cover {
        url
      }
      users {
        displayName
      }
    }
    deletedCategories: categories(
      where: { NOT: { deletedAt: null } }
      options: { sort: { title: ASC } }
    ) {
      title
      slug
      genre
      cover {
        url
      }
      users {
        displayName
      }
    }
  }
`);

const getCategories = query(async () => {
  'use server';

  const { data } = await client.query({ query: CATEGORIES });
  return {
    ...data,
    heading1: renderRawFragment('<h2>Em atividade</h2>'),
    heading2: renderRawFragment('<h2>Antigas</h2>'),
  };
}, 'categories');

export const route = {
  preload: () => getCategories(),
} satisfies RouteDefinition;

export default function Categories() {
  const data = createAsync(() => getCategories());

  const categoryItem = (
    category: NonNullable<
      ReturnType<Awaited<typeof data>>
    >['categories'][number],
  ) => (
    <li>
      <div class={styles.image}>
        <img
          loading="lazy"
          sizes="auto"
          src={category.cover.url}
          srcSet={generateWidthThumbs(category.cover.url, 1)}
          alt=""
        />
      </div>
      <div class={styles.meta}>
        <SmartA class={rootStyles.pseudo} href={`/${category.slug}`}>
          {category.title}
        </SmartA>
        <p>
          {category.genre} ·{' '}
          {conjunctionJoin(category.users.map(user => user.displayName))}
        </p>
      </div>
    </li>
  );

  return (
    <main>
      <Title>Colunas</Title>
      <article class={rootStyles['styled-links']}>
        <div class={postStyles.hero}>
          <h1>Colunas</h1>
          <p>
            Conheça as colunas dos nossos redatores, incluindo as históricas.
          </p>
        </div>
        <div class={postStyles.body}>
          {fragmentToJsx(data()?.heading1)}
          <ul class={styles.list}>
            <For each={data()?.categories}>{categoryItem}</For>
          </ul>
          {fragmentToJsx(data()?.heading2)}
          <ul class={styles.list}>
            <For each={data()?.deletedCategories}>{categoryItem}</For>
          </ul>
        </div>
      </article>
    </main>
  );
}
